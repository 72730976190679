<template>
  <div class="project-list-view">
    <div class="safty-wrapper">
      <div class="project-list-filter">
        <div class="project-list-sort">
          <div class="sort-item">按名称排序</div>
          <div class="sort-item">按价格排序</div>
        </div>
        <div class="search-input-group">
          <el-input
            v-model="keyword"
            placeholder="请输入关键字"
            @keyup.enter.native="getProductList(1)"
          ></el-input>
        </div>
      </div>

      <div class="project-list-group" v-if="result">
        <div
          class="project-list-item"
          v-for="item in result.list"
          :key="item.id"
          @click="goProductDetail(item)"
        >
          <div class="project-wrap">
            <div class="project-banner">
              <img :src="item.poster" alt="" />
            </div>
            <div class="project-info">
              <div class="project-title">{{ item.name }}</div>
              <div class="project-price">
                {{ item.price | currency }} 万元/套
              </div>
              <div class="project-tag-list">
                <div class="project-tag-item">技术服务</div>
                <div class="project-tag-item">技术服务</div>
                <div class="project-tag-item">软件</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
          :current-page="page.pageNum"
          background
          layout="prev, pager, next"
          :total="page.total"
          @current-change="getProductList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
      page: {
        pageNum: 1,
        total: 0,
      },
      result: null,
    };
  },
  mounted() {
    this.getProductList(1);
  },
  methods: {
    getProductList(current) {
      this.$axios
        .get("/product/seachProductList", {
          params: {
            pageNum: current ? current : this.page.current_page,
            condit: this.keyword,
          },
        })
        .then((res) => {
          if (res.state == 200) {
            this.result = res.data;
            this.page.pageNum = res.data.pageNum;
            this.page.total = res.data.total;
          }
        });
    },
    goProductDetail(item) {
      this.$router.push("/product-detail/" + item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.project-list-view {
  .safty-wrapper {
    width: $safety-width;
    margin: 0 auto;

    .project-list-filter {
      height: 80px;
      background: #ffffff;
      border: 1px solid #ededed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 64px;
      .project-list-sort {
        display: flex;
        .sort-item {
          width: 100px;
          height: 35px;
          line-height: 35px;
          margin-left: 37px;
          text-align: center;
        }
        .sort-item:hover {
          cursor: pointer;
          background-color: #3883ff;
          color: #fff;
        }
      }
      .search-input-group {
        margin-right: 26px;
      }
    }

    .project-list-group {
      display: flex;
      flex-wrap: wrap;
      flex-flow: row wrap;

      .project-list-item {
        margin: 15px 0;
        box-sizing: border-box;
        flex: 0 0 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;

        .project-wrap {
          width: 279px;
          border: 1px solid #efefef;
          .project-banner {
            width: 279px;
            height: 171px;
            background-color: #dddddd;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .project-info {
            width: 100%;
            padding: 28px 17px;
            box-sizing: border-box;
            .project-title {
              color: #333333;
              font-size: 22px;
            }
            .project-price {
              color: #fa6400;
              margin-top: 13px;
            }
            .project-tag-list {
              display: flex;
              flex-wrap: wrap;
              margin-top: 25px;
              .project-tag-item {
                padding: 9px 26px;
                background-color: #eeeeee;
                color: #666666;
                font-size: 12px;
                margin-right: 12px;
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }

    .pagination {
      text-align: right;
      margin-bottom: 114px;
    }
  }
}
</style>